<template>
  <van-back-top @click="onBackTop" class="!rounded-md !w-[50px] !h-[50px] !bg-primary !hover:bg-secondary">
    <van-icon name="arrow-up" size="28"/>
  </van-back-top>
</template>

<script setup>
import {track} from "~/composables/useTrackEvent";
import {TRACKERS} from "~/constants/events";

const onBackTop = () => {
  track(TRACKERS.TOP_PRODUCT_ARROW)
}
</script>

<style>
</style>