<template>
  <layout-wrapper :isFooter="true" class="bg-light-grey">
    <template #header>
      <div class="flex justify-center items-center px-3">
        <nuxt-link :to="$routerUrl('/categories')">
          <van-icon :name="$publicPath('/images/header/category.svg')" size="24"/>
        </nuxt-link>
        <home-search/>
        <van-icon :name="$publicPath('/images/header/notification.svg')" dot size="24"/>
      </div>
    </template>

    <div class="pt-3 px-3 custom-container">
      <dynamic-components :components-data="componentsData"/>
    </div>

    <client-only>
      <full-page-loader :loading="isLoading"/>
    </client-only>

    <back-to-top/>

  </layout-wrapper>
</template>

<script setup>
import HomeSearch from "@/components/home/Search"
import BackToTop from "@/components/home/BackToTop"
import {getWebHomeData} from "@/plugins/API/home"

const {isLoading, data} = getWebHomeData()
const componentsData = computed(() => data.value?.components || [])
</script>