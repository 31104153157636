<template>
  <van-popup v-model:show="showVideoDialog" :style="{ height: '100%' }" position="top">
    <video-details v-if="showVideoDialog" v-model:video-id="selectedVideoId" @close="showVideoDialog = false"/>
  </van-popup>
</template>

<script setup>
import VideoDetails from "@/components/dynamic_components/video-slider/VideoDetails";

const showVideoDialog = ref(false)
const selectedVideoId = ref(0)

const route = useRoute()
onMounted(() => {
  if (!route.query.video_id) return

  showVideoDialog.value = true
  selectedVideoId.value = route.query.video_id
})

</script>

<style scoped>

</style>